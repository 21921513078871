import { Set  } from 'immutable'
import Flightpositionprocessor from "../domain/flightpositionprocessor";
import Mapbinding from '../mapbinding/mapbinding';

// the controller connects the REST-API and the MAP-API with the buisnesslogic.

class FlightpositionController {

    constructor(layer_aircrafts_vector_source) {
        this.mapbinding = new Mapbinding(layer_aircrafts_vector_source);
    }

    handleFetchedFlightData = (newPositionData) => {
        const oldKeys = Set(this.mapbinding.getCurrentFlightKeys());
        const state = Flightpositionprocessor.processFlightData( oldKeys , 
                                newPositionData)
    
        this.mapbinding.handleNewFlights(state.get('newFlights'));
        this.mapbinding.handleMoveFlights(state.get('movedFlights'));
        this.mapbinding.handleDeleteFlights(state.get('deletedFlights'));
        this.mapbinding.updateMap();
    }
}



export default FlightpositionController;
