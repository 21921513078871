import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat  } from 'ol/proj';
import { Style, Icon } from 'ol/style';

class Mapbinding {

    constructor(layer_aircrafts_vector_source) {

        this.layer_aircrafts_vector_source = layer_aircrafts_vector_source;
    }

    // newFlights is an immutable set of flight-objects 
    handleNewFlights = (newFlights) => {
        newFlights.forEach( flight => {
            this.addAircraftSymbol(this.layer_aircrafts_vector_source, flight);
        });
    }


    handleMoveFlights = movedFlights => {

        movedFlights.forEach( (flight) => {
            const key = flight.icao24;
            const aircraft = this.layer_aircrafts_vector_source.getFeatureById(key) 
            if (aircraft != null) {
                const newLonLat = new Point(fromLonLat([flight.longitude, flight.latitude]));
                aircraft.flight.geo_altitude = flight.geo_altitude;
                aircraft.setGeometry(newLonLat);
            }
        })
    }

    handleDeleteFlights = (deletedFlights) => {

        deletedFlights.forEach( key => {
            const aircraft = this.layer_aircrafts_vector_source.getFeatureById(key) 
            if (aircraft != null) {
                this.layer_aircrafts_vector_source.removeFeature(aircraft);
            }
        });
    }

    updateMap = () => {
        this.layer_aircrafts_vector_source.changed();
    }

    addAircraftSymbol = function(layer, flight) {

        const key = flight.icao24;
        const lat = flight.latitude;
        const lon = flight.longitude;
        const geo_altitude = flight.geo_altitude;
        const heading = flight.heading;
        const callsign = flight.callsign;
    
        if (!(lat > -90 && lat < 90 && lon > -180 && lon < 180 && heading >=0 && heading <= 360)) {
            // ignore the data!
            return null;
        }
    
        const point = new Point(fromLonLat([lon,lat]));
        const aircraftIconName = 'img/plane.png';
    
        const aircraft = new Feature( {
            geometry : point,
        });
        aircraft.setId(key);
        const iconStyle = new Style({
            image: new Icon({
                src: aircraftIconName,
                scale: [0.5,0.5],
                rotation: heading
            })
            });
        aircraft.setStyle(iconStyle);    
        layer.addFeature(aircraft);
    
        aircraft.flight = flight;
    
        return aircraft;
    }

    getCurrentFlightKeys = function() {
        return this.layer_aircrafts_vector_source.getFeatures().map( f => f.getId() );
    }
}

export default Mapbinding;