import './../style.css';

if (self.getUrl === undefined) {
    self.getUrl = function() {

        // return "https://flugwege.de/flightdata/8/49.9/0";
        return "https://flugwege.de/flightdata";
    }    
}

import { Vector as VectorSource } from 'ol/source';
import { drawmap } from './mapbinding/map_dom';

import FlightpositionController from './controller/flightpositioncontroller';

const layer_aircrafts_vector_source = new VectorSource({ });
const flightpositioncontroller = new FlightpositionController(layer_aircrafts_vector_source);


self.retrieveAndProcessData = function() {
    fetch(getUrl())
        .then( (response) => response.json())
        .then( (data) => flightpositioncontroller.handleFetchedFlightData(data) );
}


drawmap(layer_aircrafts_vector_source);
retrieveAndProcessData();

// continue to retrieve data every 10 seconds ...
setInterval(retrieveAndProcessData,10000);

