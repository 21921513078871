import { Map, Set } from 'immutable'


const POS_ICAO24 = 0;
const POS_CALLSIGN = 1;
const POS_TIME_POSITION = 3;
const POS_LONGITUDE = 5;
const POS_LATITUDE = 6;
const POS_HEADING = 10;
const POS_GEO_ALTITUDE = 13;

class Flightpositionprocessor {

    static getFlightObject = (state) => {
        return { 
            icao24 : state[POS_ICAO24],
            callsign : state[POS_CALLSIGN],
            time_position: state[POS_TIME_POSITION],
            longitude : state[POS_LONGITUDE],
            latitude : state[POS_LATITUDE],
            heading : state[POS_HEADING],
            geo_altitude : state[POS_GEO_ALTITUDE]
        };
    }


    static processFlightData = (oldKeys, newPositionData) => {

        const newFlights = [];
        const movedFlights = [];

        const newKeys = [];

        for (const state of newPositionData.states) {
            const flightObject = this.getFlightObject(state);
            if (oldKeys.includes(flightObject.icao24)) {
                movedFlights.push(flightObject);
            } else {
                newFlights.push(flightObject);
            }
            newKeys.push(flightObject.icao24);
        }
        const deletedFlights = oldKeys.subtract(newKeys);
        
        return Map( { newFlights : Set(newFlights), 
            movedFlights : Set(movedFlights),
            deletedFlights : deletedFlights });        
    }


}

export default Flightpositionprocessor